import firebase from "./firebase"; // Import the initialized Firebase app

class FirebaseMessaging {
  messaging = firebase.messaging();

  async getToken() {
    try {
      const token = await this.messaging.getToken();
      return token;
    } catch (error) {
      console.error("Error fetching Firebase token:", error);
    }
  }

  async requestNotificationPermission() {
    try {
      await Notification.requestPermission();
      const token = await this.getToken();
      window.sessionStorage.setItem("fcm", token);
      return token;
    } catch (error) {
      console.error("Error requesting permission:", error);
    }
  }

  onMessage(callback) {
    this.messaging.onMessage(callback);
  }
}

export default new FirebaseMessaging();