// firebase.js

import firebase from "firebase/compat/app"; // Update import statement
import "firebase/compat/messaging"; // Import Firebase Messaging module

const firebaseConfig = {
  apiKey: "AIzaSyC68VFAEzPYHDDMhTyam99i-0CRhujt2vg",
  authDomain: "ludo-1d234.firebaseapp.com",
  projectId: "ludo-1d234",
  storageBucket: "ludo-1d234.appspot.com",
  messagingSenderId: "615811071851",
  appId: "1:615811071851:web:481930dd48406a3dddd62e",
  measurementId: "G-9W78DMH912"
};

const app = firebase.initializeApp(firebaseConfig);

export default app; // Export the initialized Firebase app
