import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { FiUploadCloud } from 'react-icons/fi';
import css from '../../../assets/styles/components/Pan.module.css';

const AdminKyc = ({ user }) => {
  const history = useHistory();
  const [frontLoaded, setFrontLoaded] = useState(null);
  const [backLoaded, setBackLoaded] = useState(null);
  const [Name, setName] = useState('');
  const [Email, setEmail] = useState('');
  const [Number, setNumber] = useState('');
  const [DOB, setDob] = useState('');
  const [submitProcess, setProcess] = useState(false);
  
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  const aadharProcess = useRef(false);

  const handleSubmitData = e => {
    e.preventDefault();
    if (user.verified === 'unverified' || user?.verified === 'reject') {
      if (aadharProcess.current === false) {
        setProcess(true);
        aadharProcess.current = true;

        const formData = new FormData();
        formData.append('Name', Name);
        formData.append('Email', Email);
        formData.append('Number', Number);
        formData.append('DOB', DOB);
        formData.append('front', frontLoaded);
        formData.append('back', backLoaded);

        if (frontLoaded && backLoaded) {
          const access_token = localStorage.getItem('token');
          const headers = {
            Authorization: `Bearer ${access_token}`
          };

          axios
            .post(baseUrl + `aadharcard`, formData, { headers })
            .then(res => {
              if (res.data.status === false) {
                Swal.fire({
                  title: res.data.msg,
                  icon: 'error',
                  confirmButtonText: 'error'
                });
              } else if (res.data.msg === false) {
                Swal.fire({
                  title: 'Duplicate Entity',
                  icon: 'error',
                  confirmButtonText: 'error'
                });
              } else {
                Swal.fire({
                  title: 'Your KYC form submitted',
                  icon: 'success',
                  confirmButtonText: 'ok'
                });
                history.push('/Profile');
              }
              aadharProcess.current = false;
              setProcess(false);
            })
            .catch(e => {
              if (e.response.status === 401) {
                localStorage.removeItem('token');
                window.location.reload();
                history.push('/login');
              }
              aadharProcess.current = false;
              setProcess(false);
            });
        } else {
          aadharProcess.current = false;
          setProcess(false);
          alert('Please fill all fields');
        }
      } else {
        alert('You have submitted a request already.');
      }
    } else {
      alert('Your request is in process.');
    }
  };

  useEffect(() => {
    let access_token = localStorage.getItem('token');
    if (!access_token) {
      window.location.reload();
      history.push('/login');
    }
  }, []);

  const handleFrontPhotoChange = e => {
    const [file] = e.target.files;
    setFrontLoaded(file);
  };

  const handleBackPhotoChange = e => {
    const [file] = e.target.files;
    setBackLoaded(file);
  };

  const theme = localStorage.getItem('theme');
  const styles = {
    backgroundColor: theme === 'light' ? '#fff' : 'black',
    color: theme === 'light' ? 'black' : '#fff',
  };

  return (
    <div className={css.container}>
      <div className={css.formContainer}>
        <h1 className={css.heading} style={styles}>Enter Details for KYC</h1>
        <p className={css.subHeading} style={styles}>
          You need to submit a document that shows that you are above 18 years of age and not a resident of Assam, Odisha, Sikkim, Nagaland, Telangana, Andhra Pradesh, Tamil Nadu, and Karnataka.
        </p>
        <p className={css.subHeading} style={styles}>
          Enter details of Aadhar Card:
        </p>
        <form className={css.form} onSubmit={handleSubmitData}>
          <div className={css.inputGroup}>
            <input
              type="text"
              name="Name"
              placeholder="Enter name"
              value={Name}
              onChange={e => setName(e.target.value)}
              required
              className={css.input}
            />
          </div>
          <div className={css.inputGroup}>
            <input
              type="text"
              name="Email"
              placeholder="Email Id"
              value={Email}
              onChange={e => setEmail(e.target.value)}
              required
              className={css.input}
            />
          </div>
          <div className={css.inputGroup}>
            <input
              type="date"
              name="DOB"
              placeholder="Date of Birth"
              value={DOB}
              onChange={e => setDob(e.target.value)}
              required
              className={css.input}
            />
          </div>
          <div className={css.inputGroup}>
            <input
              type="tel"
              name="Number"
              placeholder="Aadhar Number"
              value={Number}
              onChange={e => setNumber(e.target.value)}
              required
              className={css.input}
            />
          </div>
          <div className={css.fileUpload} onClick={() => document.getElementById('frontPhoto').click()}>
            <input
              id="frontPhoto"
              name="frontPhoto"
              type="file"
              accept="image/*"
              onChange={handleFrontPhotoChange}
              required
            />
            {!frontLoaded && (
              <div className={css.uploadPrompt}>
                <FiUploadCloud className={css.uploadIcon} />
                <div className={css.uploadText}>Upload front photo of your Aadhar Card</div>
              </div>
            )}
            {frontLoaded && (
              <div className={css.uploaded}>
                <img src="/images/file-icon.png" alt="file icon" className={css.fileIcon} />
                <div className={css.fileDetails}>
                  <div className={css.fileName}>{frontLoaded.name}</div>
                  <div className={css.fileSize}>{(frontLoaded.size / 1024 / 1024).toFixed(2)} MB</div>
                </div>
                <img
                  src="/images/global-cross.png"
                  alt="remove"
                  className={css.removeIcon}
                  onClick={() => setFrontLoaded(null)}
                />
              </div>
            )}
          </div>
          <div className={css.fileUpload} onClick={() => document.getElementById('backPhoto').click()}>
            <input
              id="backPhoto"
              name="backPhoto"
              type="file"
              accept="image/*"
              onChange={handleBackPhotoChange}
              required
            />
            {!backLoaded && (
              <div className={css.uploadPrompt}>
                <FiUploadCloud className={css.uploadIcon} />
                <div className={css.uploadText}>Upload back photo of your Aadhar Card</div>
              </div>
            )}
            {backLoaded && (
              <div className={css.uploaded}>
                <img src="/images/file-icon.png" alt="file icon" className={css.fileIcon} />
                <div className={css.fileDetails}>
                  <div className={css.fileName}>{backLoaded.name}</div>
                  <div className={css.fileSize}>{(backLoaded.size / 1024 / 1024).toFixed(2)} MB</div>
                </div>
                <img
                  src="/images/global-cross.png"
                  alt="remove"
                  className={css.removeIcon}
                  onClick={() => setBackLoaded(null)}
                />
              </div>
            )}
          </div>
          <button type="submit" className={css.submitButton}>Submit</button>
        </form>
        {submitProcess && (
          <div className={css.loader}>
            <img src="/images/LandingPage_img/loader1.gif" alt="loading" className={css.loaderImage} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminKyc;
