import React, { useEffect, useState } from "react";
import axios from "axios";
import { Form, Button, Row, Col } from "react-bootstrap";
import "./Notification.css";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "react-select";
const Notification = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [age, setAge] = React.useState("");
  const [isListOpen, setIsListOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const [selectedOption, setSelectedOption] = useState([]);

  const [input, setInput] = useState({
    sendTo: "",
    title: "",
    body: "",
    image: "",
    searchInput: [],
  });

  const data = [...selectedOption];
  const selectedValues = [];
  
  data.forEach((item) => {
    const SelectedValue = item.value;
    selectedValues.push(SelectedValue);
  });
  
 


  const [userData, setUserData] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOpt, setSelectedOpt] = useState([]);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleOptionClick = (optionValue) => {
    const optionIndex = selectedOpt.indexOf(optionValue);
    if (optionIndex > -1) {
      const updatedOptions = [...selectedOpt];
      updatedOptions.splice(optionIndex, 1);
      setSelectedOpt(updatedOptions);
    } else {
      setSelectedOpt([...selectedOpt, optionValue]);
    }
  };

  const handleUnselectAll = () => {
    setSelectedOpt([]);
  };

  const handleChange = (event) => {
    userListData();

    

    const { name, value, options } = event.target;

    if (name === "sendTo" && value === "selected") {
      setIsListOpen(true);
    } else if (name === 'secondFormSendTo') {
      const selectedValues = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);

      setInput((prevInput) => ({
        ...prevInput,
        searchInput: selectedValues.join(', '), // Join values as a string
      }));
    } else {
      setInput((prevInput) => ({
        ...prevInput,
        [name]: value,
      }));
    }
  

 


    // if (name === "secondFormSendTo") {
    //   setInput((prevInput) => ({ ...prevInput, searchInput: value }));
    // }
    // const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
    // setInput({ ...input, searchInput: selectedOptions });
  };

  function notify() {
    setIsLoading(true);
    console.log();
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const formData = {
      title: input.title,
      bodys: input.body,
      sendto: input.sendTo,
      searchInput: selectedValues,
      id: selectedOpt,
    };

    axios
      .post(`${baseUrl}admin/send-notification`, formData, headers)
      .then((response) => {
        // Handle success

        // console.log(response.data);
        if (response) {
          setIsLoading(false);
          Swal.fire({
            title: "Send Successfully",
            icon: "success",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        // Handle error
        setIsLoading(false);
        Swal.fire({
          title: "Error",
          icon: "error",
          confirmButtonText: "OK",
        });
        console.error(error);
      });
  }

  const handleSubmit = (e) => {
    setInput({});
    e.preventDefault();
    notify();
  };

  const userListData = () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const data = axios
      .get(
        baseUrl +
          `admin/get_User/all?page=${0}&_limit=${100}&_q=${
            input.searchInput
          }&_stype=${0}&_status=${0}&_Userstatus=""`,
        { headers }
      )
      .then((res) => {
        console.log(res.data);
        setUserData(res.data);
        // $('table').dataTable();
      })
      .catch((e) => alert(e));
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = userData?.admin?.filter((item) => {
    const itemData = item?.Name.toLowerCase(); // Change 'name' to the relevant property in your API data
    return itemData && itemData?.includes(searchQuery.toLowerCase());
  });

  const options = filteredData?.map((item) => ({
    value: item?.id || "",
    label: item?.Name || "Default Label",
  }));


  return (
    <div className="">
      <div className="row mt-5">
        <div className="col-12 grid-margin">
          {/* <Select   isMulti
    name="colors"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select" /> */}
          <div className="card">
            <div className="card-body text-light table_bg">
              <h5 className="dashboard_heading" onClick={notify}>
                Send Notification
              </h5>
              <div>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="selected">
                        <Form.Label>Send To</Form.Label>
                        <Form.Control
                          as="select"
                          name="sendTo"
                          value={input.sendTo}
                          onChange={handleChange}
                        >
                          <option value="">Select an option</option>
                          <option value="all">ALL USERS</option>
                          <option value="Blocked">Blocked</option>
                          <option value="Unblocked">Unblocked</option>
                          <option value="Verified">Verified</option>
                          <option value="Unverified">Unverified</option>
                          <option value="Top-player">Top Player</option>
                          <option value="active">Active</option>
                          <option value="unactive">InActive</option>
                          <option value="selected">Selected User</option>
                          {/* </select> */}
                        </Form.Control>
                      </Form.Group>

                      {/* {isListOpen && (
        <div>
         <Form.Control
                      as="select"
                      name="sendTo"
                      value={input.sendTo}
                      onChange={handleChange}
                    >
                      {
                        userData?.admin?.map((Value) => {
                          return(
                            <option value={input.sendTo}>{Value?.Name}</option>
                          )
                        })
                      }
                    </Form.Control>


                    
        </div>
      )} */}
                      {isListOpen && (
                        <Row>
                          <Col md={12}>
                            <Form.Group controlId="secondFormSendTo">
                              <Form.Label>Selected User</Form.Label>
                              <div>
                                {/* <input
                                  type="search"
                                  placeholder="What're you searching for?"
                                  aria-describedby="button-addon4"
                                  className="form-control bg-none border-0 rounded-pill mr-1 SearchBox"
                                  value={searchQuery}
                                  onChange={handleSearch}
                                /> */}
                                {/* <h1>{filteredData.Name}</h1> */}
                              </div>

                              <form
                                  as="select"
                                  name="secondFormSendTo"
                                  value={selectedOptions}
                                  onChange={handleChange}
                                  multiple
                              >

                              <Select
                                       isMulti
                                       name="colors"
                                       options={options}
                                       className="basic-multi-select text-dark"
                                       classNamePrefix="select"
                                       onChange={setSelectedOption}
                                       
                                     
                              />
                                {/* {filteredData?.map((Value) => {
                                  return (
                                    <option key={Value.Name} value={Value._id}>
                                      {Value.Name}
                                    </option>
                                  );
                                })} */}
                              </form>
                            </Form.Group>
                          </Col>
                        </Row>
                      )}

                      <Form.Group controlId="title">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          type="text"
                          name="title"
                          value={input.title}
                          onChange={handleChange}
                        />
                      </Form.Group>

                      <Form.Group controlId="body">
                        <Form.Label>Body</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="body"
                          value={input.body}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>

                    {/* <Col md={6}>
            <Form.Group controlId="image">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                name="image"
                onChange={handleChange}
              />
            </Form.Group>
  
           
  </Col>*/}
                  </Row>

                  <Button variant="primary" type="submit">
                    {isLoading ? (
                      <div>
                        <Spinner animation="grow" variant="success" />
                        <Spinner animation="grow" variant="danger" />
                        <Spinner animation="grow" variant="warning" />
                        <Spinner animation="grow" variant="info" />
                      </div>
                    ) : (
                      "SEND"
                    )}
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
